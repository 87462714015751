<!--技术质量-->
<template>
    <div class="technicalQuality">
        <el-tabs @tab-click="changeTab">
            <el-tab-pane
                v-for="(item,index) in tabList"
                :key="index"
                :label="item.label"
            >
                <span class="tab-label" slot="label"><i class="iconfont iconccgl-zhiliangshigu-1"></i> {{ item.label }}</span>
            </el-tab-pane>
        </el-tabs>
        <div class="content">
            <component ref="comp" :is="tabList[tabActive].component" :extr="extr"></component>
        </div>
        <div class="btns">
            <el-button
                class="close"
                @click="handleClose"
            >
                关闭
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'technical-quality',
    data() {
        return {
            tabList: [
                { label: '试块检测', component: () => import('./testBlock') },
                { label: '回弹检测', component: () => import('./springBackCheck') },
            ],
            tabActive: 0,
        };
    },
    props: {
        extr: Object,
    },
    methods: {
        changeTab(item) {
            this.tabActive = Number(item.index);
        },
        // 关闭弹框
        handleClose() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus" scoped>
.technicalQuality
    height 100%;
    >>> .el-tabs
        padding 0 0.25rem
        .el-tabs__item.is-active,.el-tabs__item:hover
            color #D6000F
        .el-tabs__active-bar
            background-color #D6000F
        .tab-label
            font-size 0.18rem;
    .content
        height calc(100% - 1.4rem)
    >>> .btns
        display flex
        justify-content center
        .el-button
            width: 2.6rem;
            height: 0.5rem;
            font-size 0.2rem
            margin 0 0.2rem
        .save
            color: #fff;
            background: #1577D2;
        .close
            color: #1577d2;
            background: #fff!important;
            border: 1px solid #1577d2;
</style>
